<template>
  <div id="app">
    <flipbook class="flipbook" :pages="pages" :gloss="0.15"></flipbook>
  </div>
</template>

<script>
import { ref } from 'vue';
import Flipbook from 'flipbook-vue';

export default {
  components: {
    Flipbook,
  },
  setup() {
    const pages = ref([
      null, 'pages/01.jpg', 'pages/02.jpg', 'pages/03.jpg', 'pages/04.jpg', 'pages/05.jpg',
      'pages/06.jpg', 'pages/07.jpg', 'pages/08.jpg', 'pages/09.jpg', 'pages/10.jpg',
      'pages/11.jpg', 'pages/12.jpg', 'pages/13.jpg', 'pages/14.jpg', 'pages/15.jpg',
      'pages/16.jpg', 'pages/17.jpg', 'pages/18.jpg', 'pages/19.jpg', 'pages/20.jpg',
      'pages/21.jpg', 'pages/22.jpg', 'pages/23.jpg', 'pages/24.jpg', 'pages/25.jpg',
      'pages/26.jpg', 'pages/27.jpg'
    ]);
    return { pages };
  },
};
</script>

<style>
#app {
  background-color: #333; /* Dark grey background */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flipbook {
  width: 90vw;
  height: 90vh;
  margin: 0 auto;
}
</style>
